.Container {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Centered-Content {
    flex: 0 1 calc(90% - 30px);
    overflow-y: auto;
    height: calc(100vh - (70px + 65px));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-left: 2%;
    margin-right: 2%;
    padding-top: 70px;
    padding-bottom: 65px;
}

@media screen and (max-height: 500px) {
    .Centered-Content {
        justify-content: start;
    }
}

.Left-Content {
    color: grey;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    margin-left: 2%;
    margin-right: 2%;
    padding-top: 70px;
    padding-bottom: 65px;
}

.Card {
    display: flex;
    margin: 15px;
    width: 600px;
    background-color: transparent !important;
    box-shadow: 10px 10px 6px 1px rgba(0, 0, 0, 0.75);
}

/* Media query for smaller screens */
@media screen and (max-width: 767px) {
    .Card {
        flex: 0 1 calc(90% - 30px); /* Two items per row on smaller screens */
        width: unset; /* Allow content to shrink to fit */
    }
}
